<template>
  <div>
    <KTPortlet :title="userName">
      <template v-slot:body>
        <div class="row date-picker">
          <div class="col-md-6 col-sm-12 d-flex align-items-center mb-2">
            <span class="title">Từ ngày</span>
            <b-form-datepicker
              id="from-date"
              calendar-width="100%"
              v-model="fromDate"
              v-bind="labels['vn'] || {}"
              class="ml-3"
              locale="vie"
              @input="changeChartData()"
            ></b-form-datepicker>
          </div>
          <div class="col-md-6 col-sm-12 d-flex align-items-center mb-2">
            <span class="title">Đến ngày</span>
            <b-form-datepicker
              id="to-date"
              v-model="toDate"
              calendar-width="100%"
              class="ml-3"
              locale="vie"
              v-bind="labels['vn'] || {}"
              @input="changeChartData()"
            ></b-form-datepicker>
          </div>
        </div>
        <div id="chartdiv"></div>
      </template>
      <template v-slot:foot>
        <div class="d-flex align-items-center">
          <span class="color-box" style="background-color: #67b7dc;"></span>
          <span>Tổng tiền thu (theo ngày)</span>
        </div>
        <div class="d-flex align-items-center">
          <span class="color-box" style="background-color: #fd397a;"></span>
          <span>Tổng số khách (theo ngày)</span>
        </div>
        <p class="value mb-0 mt-3">Tổng doanh thu {{ total }}</p>
        <p class="value mb-0">Tổng lượt khách {{ totalCustomer }}</p>
        <p class="value mb-0">Doanh thu trung bình {{ totalAvg }}</p>
        <p class="value mb-0">Số khách trung bình {{ totalCustomerAvg }}</p>

        <hr />
        <h5>Doanh thu trung bình</h5>
        <div class="p-0 row">
          <div
            class="value mt-3 col-xl-3 col-lg-3 col-md-6 col-sm-12"
            v-for="(product, index) in productsAvg"
            :key="index"
          >
            <div class="border-light p-2">
              <p class="value mb-0">
                Tên dịch vụ
                <strong>{{ product.name }}</strong>
              </p>
              <p class="value mb-0">
                Doanh thu
                <strong>{{ product.total }}</strong>
              </p>
              <p class="value mb-0">
                Phần trăm
                <strong>{{ product.percent }} %</strong>
              </p>
            </div>
          </div>
        </div>
      </template>
    </KTPortlet>
    <!-- <KTPortlet class="mt-3" title="Báo cáo tổng">
      <template v-slot:body>
        <ul class="table-horizontal">
          <li v-for="item in tabItems" :key="item.date" class="item">
            <p class="title">{{ item.date }}</p>
            <p class="value">{{ item.total }}</p>
            <p class="value">{{ item.totalCustomer }}</p>
          </li>
        </ul>
      </template>
    </KTPortlet> -->
  </div>
</template>

<!-- Load login custom page styles -->
<style lang="scss">
@import "@/assets/sass/pages/report/outlet.scss";
@import "./User.scss";
</style>

<script>
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import KTPortlet from "@/components/Portlet.vue";
import { getUserReport } from "@/api/user";
import { addChartLine } from "@/common/amCharts";
import * as moment from "moment";
import Loader from "@/common/loader";

export default {
  name: "UserReport",
  components: {
    KTPortlet,
  },
  data() {
    return {
      chartData: [],
      productsAvg: [],
      chart: null,
      fromDate: "",
      toDate: "",
      total: 0,
      tabItems: [],
      totalCustomer: 0,
      totalCustomerAvg: 0,
      totalAvg: 0,
      outletId: "",
      userId: "",
      labels: {
        vn: {
          labelPrevYear: "Năm trước",
          labelPrevMonth: "Tháng trước",
          labelCurrentMonth: "Tháng hiện tại",
          labelNextMonth: "Tháng tiếp theo",
          labelNextYear: "Năm tiếp theo",
          labelToday: "Hôm nay",
          labelNoDateSelected: "Không có ngày được chọn",
          labelHelp: "Sử dụng các phím mũi tên để điều hướng qua lịch.",
        },
      },
      userName: "",
    };
  },
  methods: {
    createChart(data) {
      // Create chart
      let chart = am4core.create("chartdiv", am4charts.XYChart);

      chart.paddingRight = 20;
      chart.data = data;

      let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
      dateAxis.renderer.grid.template.location = 0;

      let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
      valueAxis.tooltip.disabled = true;
      valueAxis.renderer.minWidth = 35;

      let series = chart.series.push(new am4charts.LineSeries());
      addChartLine(series, "total", "#67B7DC");

      let customersLine = chart.series.push(new am4charts.LineSeries());
      addChartLine(customersLine, "totalCustomer", "#FD397A");

      chart.cursor = new am4charts.XYCursor();

      let scrollbarX = new am4charts.XYChartScrollbar();
      chart.scrollbarY = new am4core.Scrollbar();
      scrollbarX.series.push(series);
      chart.scrollbarX = scrollbarX;
      chart.scrollbarX.parent = chart.bottomAxesContainer;
      chart.scrollbarY.parent = chart.leftAxesContainer;

      this.chart = chart;

      this.createTabData(data);
    },
    changeChartData() {
      if (!this.fromDate || !this.toDate) return;

      const fromTime = new Date(this.fromDate).getTime();
      const toTime = new Date(this.toDate).getTime();

      if (fromTime > toTime) return;

      this.getReportData(this.userId, {
        fromDate: this.fromDate,
        toDate: this.toDate,
        outletId: this.outletId,
      });
    },
    createTabData(data) {
      this.tabItems = [
        {
          date: "Tổng",
          total: "Thu",
          totalCustomer: "Khách",
        },
        ...data,
      ];
    },
    getReportData(userId, params = {}) {
      Loader.fire();
      getUserReport({
        id: userId,
        params,
      }).then(async (res) => {
        this.chartData = res.data.chartData;
        this.totalAvg = res.data.totalAvg;
        this.totalCustomerAvg = res.data.totalCustomerAvg;
        this.total = res.data.total;
        this.totalCustomer = res.data.totalCustomer;
        this.productsAvg = res.data.productsAvg;
        const chart = await this.createChart(this.chartData);
        Loader.close();
      });
    },
  },
  mounted() {
    const params = this.$router.history.current.params;
    if (params) {
      this.outletId = params.outletId;
      this.userId = params.id;
      this.userName = params.userName;
      this.getReportData(params.id, {
        outletId: params.outletId,
      });
    }
  },
  beforeDestroy() {
    if (this.chart) {
      this.chart.dispose();
    }
  },
};
</script>
